import ApiService from "../../api_service";
import * as statusMapper from "@/service/error_request_mapper.js";

export class CustomerCellsService extends ApiService {
  constructor(api) {
    super(api);
  }



  /** ajout du périmètre */
  async addPerimeter(customerCellsId, entity) {
    return this.api.addPerimeter(customerCellsId, entity)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add409AlreadyExists(customerCellsId);
        converter.convert(error);
      });
  }

  /** mise à jour du périmètre */
  async updatePerimeter(customerCellsId, perimeterId, entity) {
    return this.api.updatePerimeter(customerCellsId, perimeterId, entity)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** suppression du périmètre */
  async deletePerimeter(customerCellsId, perimeterId) {
    return this.api.deletePerimeter(customerCellsId, perimeterId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(customerCellsId);
      converter.convert(error);
    });
  }
}
